import React, { useState, useEffect } from 'react'
import { Badge } from 'antd';
import { StyledCalendar, EventsList, NotesMonth, Section } from './styles'
import firebase from 'firebase/app';
import 'firebase/functions';

function getListData(value) {
  let listData;
  if (value.month() === 1) {
    switch (value.date()) {
      case 24:
        listData = [
          { type: 'success', content: 'Course Orientation' },
        ];
        break;
      default:
    }
  }
  else if (value.month() === 2) {
    switch (value.date()) {
      case 3:
        listData = [
          { type: 'success', content: 'Segment 1: Knowing Yourself and Habits' },
        ];
        break;
      case 10:
        listData = [
          { type: 'warning', content: 'Segment 1: Presentations' },
        ];
        break;
      case 17:
        listData = [
          { type: 'success', content: 'Customer | Value Proposition | Solution' },
        ];
        break;
      case 24:
        listData = [
          { type: 'warning', content: 'Segment 2: Presentations' },
        ];
        break;
      case 31:
        listData = [
          { type: 'success', content: 'Brand As Business' },
        ];
        break;
      default:
    }
  }
  else if (value.month() === 3) {
    switch (value.date()) {
      case 14:
        listData = [
          { type: 'success', content: 'Finance | Operations' },
        ];
        break;
      case 21:
        listData = [
          { type: 'warning', content: ' Co-Lab Week' },
        ];
        break;
      default:
    }
  }
  return listData || [];
}

function dateCellRender(value) {
  const listData = getListData(value);
  return (
    <EventsList>
      {listData.map(item => (
        <li key={item.content}>
          <Badge status={item.type} text={item.content} />
        </li>
      ))}
    </EventsList>
  );
}

function getMonthData(value) {
  if (value.month() === 8) {
    return 1394;
  }
}

function monthCellRender(value) {
  const num = getMonthData(value);
  return num ? (
    <NotesMonth>
      <Section>{num}</Section>
      <span>Backlog number</span>
    </NotesMonth>
  ) : null;
}

function EventsCalendar() {
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]); 

  /*   const loadEvents = () => {
        setLoading(true);

        const retrieveEvents = firebase.functions().httpsCallable('retrieveEvents');
        let data = [];

        retrieveEvents ({
        }).then(result => {
            result.data.forEach(event => {
                if (new Date().month() === )
                data.push([resource.summary, resource.date]);
            })

            setEvents(data);
            setLoading(false);
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        loadEvents();
    },[]) */

    return(
        <StyledCalendar dateCellRender={dateCellRender} monthCellRender={monthCellRender} />
    )
}

export default EventsCalendar