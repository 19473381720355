import styled from 'styled-components'
import { Row, Card } from 'antd'

export const DashboardPage = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

export const Container = styled.div `
    background-color: white;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    padding: 20px 40px 20px 40px;
    margin-top: 20px;
    width: 80%;
`

export const FullRow = styled(Row)`
    width:100%;
    border-bottom: ${props => props.separator ? "1px solid rgba(0,0,0,0.1)" : "0px"};
    margin-bottom: ${props => props.separator ? "30px" : "0px"};
    margin-top: ${props => props.bottom ? "15px" : "0px"};
`

export const StyledCard = styled(Card)`
    background-color: white;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 0px 8px #eee;
    -moz-box-shadow: 0px 0px 8px #eee;
    box-shadow: 0px 0px 8px #eee;
`