import styled from 'styled-components'

export const LogoDiv = styled.div `
    float: left;
    width: 120px;
    margin: 14px 24px 16px 0;
`

export const LogoImg = styled.img `
    width: 100px;
    display: flex;
`

export const NameDiv = styled.div `
    float: right;
    display: flex;
    height: 31px;
    cursor: pointer;
    h1, h2, h3 {
        color: white;
    }
    span {
        margin: 8px 20px;
    }
`