import React, { useState, useEffect } from 'react'
import { StyledList, StyledSpace } from './styles'
import { Spin, Space } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import firebase from 'firebase/app';
import 'firebase/functions';

function ResourcesList() {
    const [loading, setLoading] = useState(false);
    const [resources, setResources] = useState([]); 
    
    const loadResources = () => {
        setLoading(true);

        const retrieveResources = firebase.functions().httpsCallable('retrieveResources');
        let data = [];

        retrieveResources ({
        }).then(result => {
            result.data.forEach(resource => {
                data.push([resource.name, resource.link]);
            })

            setResources(data);
            setLoading(false);
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        loadResources();
    },[])
    
    const spinnerIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    return(
        <Spin indicator={spinnerIcon} spinning={loading}>
        <StyledList
            size="large"
            bordered
            dataSource={resources}
            renderItem={item => 
                <StyledList.Item>
                    <Space size="large">
                        <StyledSpace>
                            {item[0]}
                        </StyledSpace>
                        {<a href={item[1]} target="_blank" r>Link</a>}
                    </Space>
                </StyledList.Item>}
        />
        </Spin>
    )
}

export default ResourcesList

