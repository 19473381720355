import styled from 'styled-components';
import { Row } from 'antd';

export const CommunityPage = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

export const Container = styled.div `
    background-color: white;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    padding: 20px 40px 20px 40px;
    margin-top: 20px;
    width: 80%;
`

export const CardContainer = styled.div `
    background-color: white;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 20px;
    margin-top: 20px;
    width: 290px;
    max-height: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    -webkit-box-shadow: 0px 0px 8px #eee;
    -moz-box-shadow: 0px 0px 8px #eee;
    box-shadow: 0px 0px 8px #eee;
    cursor: pointer;

    h3 {
        padding-top: 5px;
        margin-bottom: 0;
        font-weight: 700;
    }
    p {
        color: gray;
        margin-bottom: 0;
    }
`

export const NameDiv = styled.div `
    display: flex;
    height: 31px;

    h3 {
        margin-top: 5px;
        margin-left: 15px;
        font-weight: 700;
    }
`

export const FullRow = styled(Row)
`
    width:100%;
    border-bottom: ${props => props.separator ? "1px solid rgba(0,0,0,0.1)" : "0px"};
    margin-bottom: ${props => props.separator ? "30px" : "0px"};
    margin-top: ${props => props.bottom ? "15px" : "0px"};
`