import styled from 'styled-components'
import { Calendar, Menu, List, Space} from 'antd'

export const Container = styled.div`
    display: flex;
    height: 100vw;
    width: 100%;
`

export const StyledMenu = styled(Menu)`
    padding: 20px 0px 0px 0px;
    box-shadow: 4px 0px 5px rgba(0,0,0,0.1);
    font-size: 16px;
    width: 256px;
    position: relative;

    .ant-menu-item {
        height: 60px;
        display: flex;
        padding-left: 25px;
        align-items: center;
        
        &.back{
            position: absolute;
            bottom: 0;
            border-top: 1px solid rgba(0,0,0,0.1);
            width: 100%;
            padding-left: 50px;
        }
    }
`

export const Content = styled.div`
    border-radius: 8px;
    padding: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`

export const StyledCalendar = styled(Calendar)`
    padding: 20px;
    margin: 40px;
`

export const EventsList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;

    .ant-badge-status {
        width: 100%;
        overflow: hidden;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`

export const NotesMonth = styled.div`
    font-size: 28px;
    text-align: center;
`

export const Section = styled.section`
    font-size: 28px;
`

export const StyledList = styled(List)`
    background-color: white;
    border: none;
    margin: 40px;
`

export const StyledSpace = styled(Space)`
    width: 30vw;
`