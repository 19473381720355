import React, { useEffect, useState, useContext } from 'react'
import { UserContext } from '../../contexts/UserContext' 
import { Row, Col, Avatar, Form, Input, Button, Upload, Spin, message } from 'antd'
import { MailOutlined, UploadOutlined, UserOutlined, LoadingOutlined, BulbOutlined, InstagramOutlined, CloudOutlined, PhoneOutlined} from '@ant-design/icons'
import { SettingsPage, Container, FullRow, UploadButton } from './styles'
import './formStyles.scss'

function SettingsForm({onFinish, onImageUpload, data}) {
    const { TextArea } = Input;
    const [ form ] = Form.useForm();
    const { userInfo } = useContext(UserContext) 
    const [ infoLoader, setInfoLoader ] = useState(false);
    const [ imageLoader, setImageLoader ] = useState(false);

    useEffect(()=>{
        form.setFieldsValue(data);
        console.log(data)
    }, [data])

    const handleSubmit = async (data) => {
        setInfoLoader(true);
        await onFinish(data);
        setInfoLoader(false);
    }

    const handleImage = async (file) => {
        setImageLoader(true);
        await onImageUpload(file);
        setImageLoader(false);
    }

    const beforeUpload = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isLt2M;
    }

    const spinnerIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;


    return(
        <Spin indicator={spinnerIcon} spinning={infoLoader}>
            <SettingsPage>
            <Container>
                    <h1>User Settings</h1>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish ={ handleSubmit }
                        style={{width: "100%"}}
                    >
                        <FullRow gutter={16} separator='true'>
                            <Col xs={24} lg={6} xl={6} style={{textAlign: "center"}}>
                                <Spin indicator={spinnerIcon} spinning={imageLoader}>   
                                    {userInfo.photoURL ? 
                                    <Avatar size = { 128 } src={userInfo.photoURL} />
                                    : <Avatar size = { 128 } icon = { <UserOutlined /> }/>
                                    }
                                    <br/>
                                    <Upload accept="image/*" showUploadList={false} beforeUpload={beforeUpload} customRequest={handleImage}>
                                        <UploadButton icon = { <UploadOutlined /> } type = 'primary'>
                                            Upload
                                        </UploadButton>
                                    </Upload>
                                </Spin>
                            </Col>
                            <Col xs={24} lg={9} xl={9}>
                                <Form.Item
                                    label = {<b>Name</b>}
                                    name='displayName'
                                    initialValue = {data.displayName}
                                    rules={[{ required: true }]}
                                >
                                    <Input prefix={<UserOutlined />} placeholder="Full name"/>
                                </Form.Item>

                                <Form.Item
                                    label = {<b>Phone Number</b>}
                                    name='phoneNumber'
                                    initialValue = {data.phoneNumber}
                                >
                                    <Input prefix={<PhoneOutlined />} placeholder="Phone number"/>
                                </Form.Item>

                                <Form.Item
                                    label = {<b>Idea</b>}
                                    name='idea'
                                    initialValue = {data.idea}
                                    rules={[{ required: true }]}
                                >
                                    <Input prefix={<BulbOutlined />} placeholder="Put your ideas here"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={9} xl={9}>
                                <Form.Item
                                    label = {<b>Email</b>}
                                    name="email"
                                    initialValue = {data.email}
                                    rules={[
                                    {type: 'email', message: 'The input is not valid email!'},
                                    { required: true, message: 'Please input your email!' }]}
                                >
                                    <Input prefix={<MailOutlined />} placeholder="Email"/>
                                </Form.Item>

                                <Form.Item
                                    label = {<b>Instagram</b>}
                                    name='instagramURL'
                                    initialValue = {data.instagramURL}
                                >
                                    <Input prefix={<InstagramOutlined />} placeholder="Type your Instagram username"/>
                                </Form.Item>

                                <Form.Item
                                    label = {<b>Website</b>}
                                    name='websiteURL'
                                    initialValue = {data.websiteURL}
                                >
                                    <Input prefix={<CloudOutlined />} placeholder="Enter your website URL"/>
                                </Form.Item>
                            </Col>
                        </FullRow>
                        <FullRow>
                            <Col span={24}>
                                <Form.Item
                                    label = {<b>Why Statement</b>}
                                    name='whyStatement'
                                    className='settings-input'
                                    initialValue = {data.whyStatement}
                                >
                                    <TextArea placeholder="Why are you here?" autoSize={{ minRows: 3, maxRows: 5 }}/>
                                </Form.Item>
                                <Form.Item style={{textAlign: "center"}}>
                                    <Button htmlType = 'submit' type = 'primary' size = 'large'>Save Changes</Button>
                                </Form.Item>
                            </Col>
                        </FullRow>
                    </Form>
            </Container>
            </SettingsPage>
        </Spin>
    )
}

export default SettingsForm
