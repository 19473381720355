import React, { useState, useEffect, useContext } from 'react'
import firebase from 'firebase'
import { notification } from 'antd'
import NavBar from '../../Components/NavBar'
import { UserContext } from '../../contexts/UserContext'
import { SignupPage, Container } from './styles'
import SignupForm from './form'

function Signup() {
    const storeUserInfo = firebase.functions().httpsCallable('storeUserInfo');
  
    const [errorVisibility, setErrorVisibility] = useState(false)
    const { reloadUser } = useContext(UserContext);

    const [errorContent, setErrorContent] = useState({
      title: '',
      message: ''
    })
    
    useEffect(() => {
      if (errorVisibility) {
        openNotificationWithIcon('error')
      }
    }, [errorVisibility])

    const openNotificationWithIcon = type => {
      notification[type]({
        message: errorContent.title,
        description: errorContent.message,
      });

      setTimeout(() => {
        setErrorVisibility(false)
      },5000)
    };

    const signUpWithFirebase = ({email, password, name}) => {
        return firebase.auth().createUserWithEmailAndPassword(email, password)
        .then(async () => {
          await storeUserInfo({displayName: name});
          await reloadUser();
        })
        .catch((error) => {
          setErrorContent({
            'title': 'Error creating account',
            'message': error.message
          })
  
          setErrorVisibility(true)
        });
      }

    return(
        <SignupPage>
            <Container>
              <h2>Sign Up</h2>
              <SignupForm
                  signUpWithFirebase = { signUpWithFirebase } 
                  setErrorContent = { setErrorContent } 
                  setErrorVisibility = { setErrorVisibility }
              />
            </Container>
        </SignupPage>
    )
}

export default Signup