import './App.scss';
import UserContextProvider from './contexts/UserContext'
import AppRoutes from './AppRoutes'

function App() {
  return (
    <UserContextProvider>
      <div className="App">
        <AppRoutes />
      </div>
    </UserContextProvider>
  );
}

export default App;
