import React, { useState, useEffect, createContext } from 'react'
import firebase from 'firebase';

const initialState = {
    userAuth: false,
    userInfo: null
}

export const UserContext = createContext({...initialState})

function UserContextProvider(props) {
    const [userAuth, setUserAuth] = useState(firebase.auth().currentUser ? true : false)
    const [userInfo, setUserInfo] = useState(undefined)

    useEffect(()=> {
        firebase.auth().onAuthStateChanged(firebaseUser => loadUser(firebaseUser))
    }, []);

    const loadUser = async (firebaseUser) => {
        if (firebaseUser) {
            const result = await firebaseUser.getIdTokenResult()
            
            //var currentUser = {...firebaseUser, claims: result.claims }

            var currentUser = {
                uid: firebaseUser.uid,
                photoURL: firebaseUser.photoURL,
                displayName: firebaseUser.displayName,
                email: firebaseUser.email,
                claims: result.claims
            }

            if (userInfo === currentUser) return;

            setUserInfo(currentUser);
            setUserAuth(true);
        }
        else {
            setUserAuth(false);
            setUserInfo(undefined);
        }
    };
      
    const reloadUser = async () => {
        await firebase.auth().currentUser?.reload()
        return loadUser(firebase.auth().currentUser);
    }

    return(
        <UserContext.Provider value = {{userAuth, userInfo, reloadUser}}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContextProvider