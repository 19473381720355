import { Row, Col } from 'antd'
import { StyledCard } from './styles'

function DashboardGrid() {
    const style = { background: '#0092ff', padding: '8px 0' };
    
    return(
        <div className="site-card-wrapper">
            <Row gutter={16}>
                <Col span={12}>
                    <StyledCard title="Weekly Progress" bordered={true}>
                    Content
                    </StyledCard>
                </Col>
                <Col span={12}>
                    <StyledCard title="Upcoming Events" bordered={true}>
                    Content
                    </StyledCard>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <StyledCard title="My Weekly Goals" bordered={true}>
                    Content
                    </StyledCard>
                </Col>
                <Col span={6}>
                    <StyledCard title="Historic Karma" bordered={true}>
                    Content
                    </StyledCard>
                </Col>
                <Col span={6}>
                    <StyledCard title="Historic Persistence" bordered={true}>
                    Content
                    </StyledCard>
                </Col>
            </Row>
        </div>
    )
}

export default DashboardGrid