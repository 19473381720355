import React, { useState } from 'react'
import EventsCalendar from './calendar'
import ResourcesList from './list'
import { CalendarOutlined, BarsOutlined } from '@ant-design/icons'
import { Container, StyledMenu, Content } from './styles'

function Resources() {
    const [menuOption, setMenuOption] = useState('events')

    const handleMenuClick = (e) => {
        setMenuOption(e.key)
    }

    return(
        <Container>
            <StyledMenu theme = 'light' onClick = { handleMenuClick } mode = "inline">
                    <StyledMenu.Item key='events' icon = { <CalendarOutlined /> }>
                        Events 
                    </StyledMenu.Item>
                    <StyledMenu.Item key='resources' icon = { <BarsOutlined /> }>
                        Resources
                    </StyledMenu.Item>
            </StyledMenu>
            <Content>
                {
                    menuOption === 'events' ? <EventsCalendar/> : <ResourcesList/>
                }
            </Content>
        </Container>
    )
}

export default Resources