import React, {useContext} from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { UserContext } from './contexts/UserContext'
import Login from './views/Login'
import Signup from './views/Signup'
import Dashboard from './views/Dashboard'
import Resources from './views/Resources'
import Community from './views/Community'
import UserSettings from './views/UserSettings'
import NavBar from './Components/NavBar'

const defaultPublic ='/login'
const defaultPrivate = '/dasboard'

const PublicRoutes = (
    <>
        <Route path ='/login' exact component = {Login} />
        <Route path = '/signup' exact component = {Signup} />
        <Route path = '/' exact><Redirect to ='/login' /></Route>
    </>
)

const PrivateRoutes = (
    <>
        <Route path ='/dashboard' exact component = {Dashboard} />
        <Route path = '/resources' exact component = {Resources} />
        <Route path = '/community' exact component = {Community} />
        <Route path = '/settings' exact component = {UserSettings} />
        <Route path = '/' exact><Redirect to ='/resources' /></Route>
        <Route path = '/login' exact><Redirect to ='/resources' /></Route>
        <Route path = '/signup' exact><Redirect to ='/resources' /></Route>

    </>
)

function AppRoutes() {
    const { userAuth } = useContext(UserContext)

    return(<>
        <Router>
            <NavBar>
            <Switch>
                {
                    userAuth 
                    ? PrivateRoutes
                    : PublicRoutes
                }
            </Switch>
            </NavBar>
        </Router>
    </>)

}

export default AppRoutes