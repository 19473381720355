import firebase from 'firebase'

var provider = new firebase.auth.GoogleAuthProvider();

export const loginWithGoogle = () => {
    const storeUserInfo = firebase.functions().httpsCallable('storeUserInfo');

    firebase.auth().signInWithPopup(provider)
        .then(async(result) => {
            if (result.additionalUserInfo.isNewUser) {
                await storeUserInfo({ displayName: result.user.displayName });
            }
        }).catch((error) => {
            // Handle Errors here.
            console.error('error', error.message);

            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
        });
}