import React, { useState } from 'react'
import { Spin, List, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { DashboardPage, Container, FullRow } from './styles'
import DashboardGrid from './grid'

function Dashboard() {
    const [ loading, setLoading ] = useState(false);

    const spinnerIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    return(
        <DashboardPage>
            <Container>
                <FullRow separator='true'>
                    <h2>Your Dashboard</h2>
                </FullRow>
                <Spin indicator={spinnerIcon} spinning={loading}>
                    <DashboardGrid/>
                </Spin>
            </Container>
        </DashboardPage>
    )
}

export default Dashboard