import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import firebase from 'firebase';

import { Layout, Menu, Avatar, Popover } from 'antd';
import { UserOutlined, HomeOutlined, UserAddOutlined, LogoutOutlined, TeamOutlined, CalendarOutlined } from '@ant-design/icons';
import { LogoDiv, LogoImg, NameDiv } from './styles';

function NavBar({children}) {
    const { userAuth, userInfo } = useContext(UserContext);
    const [ openMenu, setOpenMenu ] = useState(false);

    const history = useHistory();

    const handleLogout = () => {
        firebase.auth().signOut().then(async ()=>{
            // Sleep for a milisecond
            await new Promise(r => setTimeout(r, 1));
            history.push('/');
          })
          .catch(console.log);
    };

    const MenuOverlay = (
        <Menu style={{border: "none"}} selectable={false}>
            { userAuth ? <>
            <Menu.Item key="1" icon={<UserOutlined />} onClick={()=>{history.push("/settings")}}>
            Profile Settings
            </Menu.Item>
            <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleLogout}>
            Log Out
            </Menu.Item>
            </>
            : <>
            <Menu.Item key="2" icon={<UserOutlined />} onClick={()=> {history.push("/login")}}>
            Log In
            </Menu.Item>
            <Menu.Item key="3" icon={<UserAddOutlined />} onClick={()=> {history.push("/signup")}}>
            Sign Up
            </Menu.Item>
            </>}
        </Menu>
      )

    return(<>
            <Layout>
                <Layout.Header
                    style={{backgroundColor: "#2b2b2b", boxShadow: "2px 0 10px 4px rgb(0 0 0 / 25%)"}}
                >
                    <LogoDiv className="logo">
                        <LogoImg alt='logo' src={require('../../assets/1909-white.png').default}/>
                    </LogoDiv>
                
                    { userAuth && 
                        <>  <Popover
                                content={MenuOverlay}
                                trigger="click"
                                visible={openMenu}
                                onVisibleChange={setOpenMenu}
                                align={{ offset: [15, -19] }}
                                >

                                <NameDiv onClick={()=>{setOpenMenu(true)}}>
                                    { userInfo.photoURL ? <Avatar size = { 45 } src={userInfo.photoURL} />
                                    : <Avatar size = { 45 } icon = { <UserOutlined /> }/> }
                                    <h3>{userInfo.displayName}</h3>
                                </NameDiv> 
                            </Popover>
                        </>
                    }

                    <Menu theme="dark" mode="horizontal" style={{borderRadius: "5px", backgroundColor: "#2b2b2b"}} selectable={false}>
                        { userAuth ? <>
                        <Menu.Item key="1" icon={<HomeOutlined />} onClick={()=>{history.push("/dashboard")}}>
                        Dashboard
                        </Menu.Item>
                        <Menu.Item key="2" icon={<TeamOutlined />} onClick={()=>{history.push("/community")}}>
                        Community
                        </Menu.Item>
                        <Menu.Item key="3" icon={<CalendarOutlined />} onClick={()=>{history.push("/resources")}}>
                        Resources
                        </Menu.Item>
                        </>
                        : <>
                        <Menu.Item key="1" icon={<HomeOutlined />} onClick={()=> {history.push("/")}}>
                        Home
                        </Menu.Item>
                        <Menu.Item key="2" icon={<UserOutlined />} onClick={()=> {history.push("/login")}}>
                        Log In
                        </Menu.Item>
                        <Menu.Item key="3" icon={<UserAddOutlined />} onClick={()=> {history.push("/signup")}}>
                        Sign Up
                        </Menu.Item>
                        </>}
                    </Menu>

                </Layout.Header>
                <Layout>
                    {children}
                </Layout>
            </Layout>
    </>
    )
}

export default NavBar