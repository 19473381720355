import React, { useState } from 'react'
import { Avatar, Form, Input, Button, Col, Row, message, Modal } from 'antd'
import { MailOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { CardContainer, FullRow, NameDiv } from './styles'

function UserCard({data}){
   const [ isModalVisible, setIsModalVisible ] = useState(false);

   const showProfileDetails = () => {
      setIsModalVisible(true);
   };

   const handleClose = () => {
      setIsModalVisible(false);
   };

   return(
      <>
      <CardContainer onClick={showProfileDetails}>
         <FullRow>
            <Col span={8}>
            { data.photoURL ? <Avatar size = { 64 } src={data.photoURL} />
                           : <Avatar size = { 64 } icon = { <UserOutlined /> }/> }
            </Col>
            <Col span={16} >
               <h3>{data.displayName}</h3>
               <p>{data.idea}</p>
            </Col>
         </FullRow>
         <FullRow bottom='true'>
            <p>"{data.whyStatement}"</p>
         </FullRow>
      </CardContainer>
      <Modal title="Basic Modal" visible={isModalVisible} onCancel={handleClose} footer={null} title="">
      <FullRow>
         <Col span={8}>
         { data.photoURL ? <Avatar size = { 128 } src={data.photoURL} />
                        : <Avatar size = { 128 } icon = { <UserOutlined /> }/> }
         </Col>
         <Col span={16} >
            <h1>{data.displayName}</h1>
            <p>"{data.idea}"</p>
         </Col>
         </FullRow>
         <FullRow bottom='true'>
            <Col span={8}>
               <h3>Contact Info</h3>
               <p>{data.email}</p>
               <p>{data.phoneNumber}</p>
               <p>{data.instagramURL}</p>
            </Col>
         </FullRow>
      </Modal>
      </>
   )
}

export default UserCard